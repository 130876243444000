/* Carousel container */
.carousel {
  position: relative;
  width: 60%; /* Default carousel width for desktop */
  max-width: 900px; /* Prevents it from being too wide */
  margin: 0 auto; /* Center the carousel */
  overflow: hidden; /* Hide any overflow */
  display: flex;
  align-items: center;
  border-radius: 10px; /* Rounded corners */
}

/* Carousel track for images */
.carousel-track {
  display: flex;
  gap: 10px; /* Maintain spacing between images */
  transition: transform 0.5s ease-in-out; /* Smooth scrolling animation */
  margin: 0 -5px; /* Offset the gap to prevent white space */
}

/* Carousel images */
.carousel-image {
  flex: 0 0 calc(33.333% - 10px); /* Each image takes up 1/3 of the container, minus spacing */
  max-height: 400px; /* Prevent images from growing too large */
  object-fit: cover; /* Ensure proper scaling */
  width: 100%; /* Ensure the image fills its container */
  border-radius: 10px; /* Match carousel's rounded corners */
}

/* Adjustments for tablet and smaller screens */
@media (max-width: 768px) {
  .carousel {
    width: 90%; /* Increase width for smaller screens */
    margin: 0 auto; /* Center the carousel */
  }

  .carousel-track {
    gap: 5px; /* Reduce spacing between images slightly */
    margin: 0 -2.5px; /* Adjust margin to match reduced gap */
  }

  .carousel-image {
    max-height: 300px; /* Adjust max height for smaller screens */
  }
}

/* Adjustments for very small screens (e.g., phones) */
@media (max-width: 480px) {
  .carousel {
    width: 95%; /* Even wider for very small screens */
  }

  .carousel-image {
    max-height: 250px; /* Further reduce height for smaller devices */
  }
}

/* Description text */
.description {
  font-size: 1.2rem;
  text-align: center; /* Center the text horizontally */
  color: #666;
  line-height: 1.5; /* Improve readability */
  margin: 0 auto; /* Center the block horizontally */
  padding: 2rem 0; /* Add vertical padding */
  max-width: 650px; /* Optional: Limit the width for better readability */
}

@media (max-width: 768px) {
  .description {
    margin-left: 1rem; /* Add left margin on smaller screens */
    margin-right: 1rem; /* Add right margin on smaller screens */
  }
}

@media (max-width: 480px) {
  .description {
    margin-left: 1.5rem; /* Add more margin for very small screens */
    margin-right: 1.5rem;
  }
}

/* Title styles */
.grad-portfolio-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #000000;
  opacity: 0; /* Hidden by default */
  transform: translateY(20px); /* Initial offset */
  transition: all 0.8s ease-in-out;
}

.grad-portfolio-title.visible {
  opacity: 1; /* Fade in */
  transform: translateY(0); /* Slide into place */
}

.grad-portfolio-title.hidden {
  opacity: 0;
  transform: translateY(20px);
}

/* Spacer for vertical spacing */
.spacer {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

/* Divider for separating sections */
.divider {
  max-width: 800px; /* Adjust width as needed */
  margin: 3rem auto; /* Add vertical spacing */
  border: none;
  border-top: 2px solid #ccc; /* Adjust color and thickness */
  opacity: 0.7; /* Optional: Makes it less prominent */
}

/* Adjustments for mobile divider spacing */
@media (max-width: 768px) {
  .divider {
    margin: 3rem 1rem; /* Add left and right space for smaller screens */
  }
}

@media (max-width: 480px) {
  .divider {
    margin: 3rem 2rem; /* Add even more left and right space for phones */
  }
}
